import React from "react";
import { motion } from "framer-motion";
import { Box, Stack } from "@mui/material";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import AppContainer from "components/Atoms/Container/AppContainer";

const CommServices = ({
  title,
  body,
  otherChildren,
  image,
  collapse,
  titleStyle,
  sx,
  initialLength,
}) => {
  return (
    <AppContainer
      alignItems={{ xs: "center" }}
      sx={{ pb: 3, flexDirection: { xs: "column", md: "row" }, ...sx }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "center" },
          justifyContent: { xs: "center", md: "space-between" },
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 3, md: 0 },
        }}
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          // viewport={{ once: true }}
          transition={{ duration: 0.7 }}
          whileHover={{ scale: 1 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
        >
          <Box component="img" src={image} width={{ xs: "100%", md: "80%" }} alt="Communication services"/>
        </motion.div>

        <Stack
          sx={{
            position: { xs: "static", md: "relative" },
            top: { md: "-70px" },
            marginLeft: { xs: 0, md: "-140px" },
            zIndex: 2,
            width: { xs: "100%", md: "57%" },
            textAlign: { xs: "center", md: "start" },
            alignItems: { xs: "center", md: "flex-start" },
          }}
          gap={3}
        >
          <Title text={title} sx={titleStyle} />
          <Body
            text={body}
            sx={{
              textAlign: { xs: "center", md: "start" },
              width: { xs: "100%", md: "479px" },
            }}
            collapse={collapse}
            initialLength={initialLength}
          />
          {otherChildren && otherChildren}
        </Stack>
      </Box>
    </AppContainer>
  );
};

export default CommServices;
