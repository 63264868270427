import Logo from "./images/logo.png";
import Usersetting from "./icons/usersetting.svg";
import Home from "./icons/home.svg";
import User from "./icons/user.svg";
import Assistant from "./icons/assistant.svg";
import Courses from "./icons/courses.svg";
import Setting from "./icons/setting.svg";
/* import imageIcon from "./icons/image.svg";
import deleteIcon from "./icons/delete.svg";
import editIcon from "./icons/edit.svg";
import videoIcon from "./icons/video.svg";
import exportIcon from "./icons/export.svg";
import plusCircleIcon from "./icons/plus-circle.svg";
import searchIcon from "./icons/search.svg";
import filterIcon from "./icons/filter.svg"; */
import AddPersonIcon from "./icons/add-person.svg";
import SourceIcon from "./icons/source.svg";
import AttachedIcon from "./icons/attached.svg";
import EmptyQuestionsIcons from "./icons/empty-questions.svg";
import QuestionBankIcon from "./icons/question-bank.svg";
import Delete from "./icons/Delete-icon.svg";
import Download from "./icons/Download.svg";
import Polygon from "./icons/polygon.svg";
import SMS from "./icons/SMS.svg";
import Whatsapp from "./icons/whatsapp.svg";
import Whatsapp2 from "./icons/WhtsappDark.svg";
import Promotional from "./icons/promotional.svg";
import Authentication from "./icons/authentication.svg";
import Transactional from "./icons/transactional.svg";
import Reminders from "./icons/reminders.svg";
import Notifications from "./icons/notifications.svg";
import LinkedIn from "./icons/LinkedIn.svg";
import Facebook from "./icons/Facebook.svg";
import Twitter from "./icons/Twitter.svg";
import Instagram from "./icons/Instagram.svg";
import WaveBackground from "./images/Wave.png";
import WaveBackground2 from "./images/Wave2.png";
import Earth from "./images/Earth.png";
import Frame from "./images/Frame.png";
import QuestionMark from "./images/QuestionMark.png";
import Email from "./icons/email.svg";
import Phone from "./icons/phone.svg";
import Location from "./icons/location.svg";
import NextArrow from "./icons/NextArrow.svg";
import PrevArrow from "./icons/PrevArrow.svg";
import Msg1 from "./icons/msg1.svg";
import Msg2 from "./icons/msg2.svg";
import Msg3 from "./icons/msg3.svg";
import Security from "./icons/security.svg";
import Gear from "./icons/gear.svg";
import Conversation from "./images/coversation.png";
import Solution1 from "./images/solution1.png";
import Solution2 from "./images/solution2.png";
import Solution3 from "./images/solution3.png";
import Solution4 from "./images/solution4.png";
import Solution5 from "./images/solution5.png";
import Solution6 from "./images/solution6.png";
import Solution7 from "./images/solution7.png";
import Envelope from "./images/envelope.png";
import Engagement from "./images/Engagement.png";
import Evaluation from "./images/Evaluation.png";
import Retention from "./images/Retention.png";
import Support from "./images/Support.png";
import Strategy1 from "./images/strategy1.png";
import Strategy2 from "./images/strategy2.png";
import Strategy3 from "./images/strategy3.png";
import Strategy4 from "./images/strategy4.png";
import Hacker from "./images/Hacker.png";
import AuthVerify from "./images/AuthVerify.png";
import SecurityLock from "./images/SecurityLock.png";
import Definition from "./images/Definition.png";
import Reminder from "./images/Reminder.png";
import HealthCare from "./images/HealthCare.png";
import Retail from "./images/Retail.png";
import Travel from "./images/Travel.png";
import Banking from "./images/Banking.png";
import Telecommunications from "./images/Telecommunications.png";
import Education from "./images/Education.png";
import SMSNotifications from "./images/Notifications.png";
import NotificationsReason1 from "./images/NotificationsReason1.png";
import NotificationsReason2 from "./images/NotificationsReason2.png";
import NotificationsReason3 from "./images/NotificationsReason3.png";
import NotificationsReason4 from "./images/NotificationsReason4.png";
import Keyfeature1 from "./icons/bell.svg";
import Keyfeature2 from "./icons/care.svg";
import Keyfeature3 from "./icons/cloud.svg";
import Keyfeature4 from "./icons/headset.svg";
import CopyRight from "./icons/CopyRight.svg";
import ShoppingCart from "./icons/ShoppingCart.svg";
import Calendar from "./icons/Calendar.svg";
import Lock from "./icons/Lock.svg";
import Currency from "./icons/Currency.svg";
import Upload from "./icons/Upload.svg";
import Percentage from "./icons/Percentage.svg";
import Voice from "./icons/voice.svg"
import DID from "./icons/did.svg"
import Wholesales from "./icons/wholesales.svg"


// clients updates
import UserAuth from "./icons/userAuth.svg"
import UserConsent from "./icons/userConsent.svg"
import VoiceGuide from "./icons/voiceGuide.svg"
import PromoCamp from "./icons/promoCamp.svg"
import LeadGeneration from "./icons/leadGeneration.svg"
import CustomerSupport from "./icons/customerSupport.svg"
import VoiceService from "./images/voiceServices.png"
import A2P from "./images/A2P.png"
import P2P from "./images/P2P.png"
import HandShake from "./images/handShake.png"
import PersonOnComputer from "./images/personOnComputer.png"
import APIPortal from "./icons/apiPortal.svg"
import DIDnumbers from "./icons/didNumbers.svg"
import LocalTraffic from "./icons/localTrafficTermination.svg"
import PSTN from "./icons/PSTN.svg"
import TollFreeNumbers from "./icons/tollFreeNumbers.svg"
import TwoWaySMS from "./icons/twoWaySMS.svg"
import APIavailable from "./icons/APIavailable.svg"
import EmergencyServices from "./icons/emergencyServices.svg"
import MonitorDID from "./icons/monitorDID.svg"
import PortabilityAvailability from "./icons/portabilityAvaliablity.svg"
import PSTNreplacement from "./icons/PSTNreplacement.svg"
import TechSupport from "./icons/techSupport.svg"
import RCS from "./icons/RCS.svg"
import Shield from "./icons/sheild.svg" 
import Media from "./icons/media.svg"
import Connect from "./icons/connect.svg"
import Delivery from "./icons/delivery.svg"
import Mobile from "./images/Mobile.png"
import Verification from "./images/verification.svg"
import Feature1 from "./images/feature1.svg"
import Feature2 from "./images/feature2.svg"
import Feature3 from "./images/faeture3.png"
import Feature4 from "./images/feature4.png"
import Feature5 from "./images/feature5.png"  
import Verify from "./icons/verify.svg"
import Custom  from "./icons/custom.svg"
import Cards from "./icons/cards.svg"
import Carousel from "./icons/carsoules.svg"
import Action from "./icons/action.svg"
import Tick from "./icons/tick-square.svg"
import DidServices from "./images/didservices.png"
import LocalDID from "./images/localdid.png"
import MobileDID from "./images/mobiledid.png"
import TollDID from "./images/tolldid.png"
import GeoDID from "./images/geodid.png"
import Setup from "./icons/setup.svg"
import Management from "./icons/management.svg"
import Analytics from "./icons/analytics.svg"
import TwoWay from "./icons/twoway.svg"
import Versatile from "./icons/versatile.svg"
import Channels from "./icons/channels.svg"
import Domestic from "./icons/domestic.svg"
import International from "./icons/international.svg"
import Wifi from "./icons/wifi.svg"
import TollFree from "./icons/tollfree.svg"
import Local from "./icons/local.svg"
import TollNumber from "./icons/tollnumber.svg"

import Appointment from "./icons/Appointment.svg";
import INOVA from "./images/INOVA.png";
import About1 from "./images/About1.png";
import About2 from "./images/About2.png";
import ContactUs from "./images/Contact-us.png";
import ContactSupport from "./images/ContactSupport.png";
import JoinUs from "./images/JoinUs.png";
import Discounts from "./images/Discounts.png";
import SignUp from "./images/SignUp.png";
import Partners from "./images/Partners.png"
import IVR from "./images/IVR.png"
import TextToSpeech from "./images/textToSpeech.png"
import MaskedCalling from "./images/maskedCalling.png"
import NotificationsPhone from "./images/notificationsPhone.png"
import CallRecording from "./images/callRecording.png"
import RealtimeAn from "./images/realtimeAn.png"
import GuyOnPhone from "./images/guyOnPhone.png"

export const images = {
  Logo,
  WaveBackground,
  Earth,
  Frame,
  QuestionMark,
  Solution1,
  Solution2,
  Solution3,
  Solution4,
  Solution5,
  Solution6,
  Solution7,
  Conversation,
  Engagement,
  Evaluation,
  Retention,
  Support,
  Envelope,
  Strategy1,
  Strategy2,
  Strategy3,
  Strategy4,
  Hacker,
  AuthVerify,
  SecurityLock,
  WaveBackground2,
  Definition,
  Reminder,
  HealthCare,
  Retail,
  Travel,
  Banking,
  Telecommunications,
  Education,
  SMSNotifications,
  NotificationsReason1,
  NotificationsReason2,
  NotificationsReason3,
  NotificationsReason4,
  INOVA,
  About1,
  About2,
  ContactUs,
  ContactSupport,
  JoinUs,
  Discounts,
  SignUp,
  Partners,
  IVR,
  MaskedCalling,
  NotificationsPhone,
  TextToSpeech,
  RealtimeAn,
  CallRecording,
  GuyOnPhone,
  VoiceService,
  A2P,
  P2P,
  HandShake,
  PersonOnComputer,
  Mobile,
  Verification,
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  Feature5,
  DidServices,
  LocalDID,
  MobileDID,
  TollDID,
  GeoDID
};

export const icons = {
  Usersetting,
  Home,
  User,
  Assistant,
  Courses,
  Setting,
  AddPersonIcon,
  SourceIcon,
  AttachedIcon,
  EmptyQuestionsIcons,
  QuestionBankIcon,
  Delete,
  Download,
  Polygon,
  SMS,
  Whatsapp,
  Whatsapp2,
  Promotional,
  Authentication,
  Transactional,
  Reminders,
  Notifications,
  LinkedIn,
  Facebook,
  Twitter,
  Instagram,
  Email,
  Phone,
  Location,
  Msg1,
  Msg2,
  Msg3,
  NextArrow,
  PrevArrow,
  Keyfeature1,
  Keyfeature2,
  Keyfeature3,
  Keyfeature4,
  Security,
  Gear,
  CopyRight,
  ShoppingCart,
  Calendar,
  Lock,
  Currency,
  Appointment,
  Upload,
  Percentage,
  Voice,
  DID,
  Wholesales,
  UserAuth,
  UserConsent,
  VoiceGuide,
  PromoCamp,
  LeadGeneration,
  CustomerSupport,
  APIPortal,
  DIDnumbers,
  LocalTraffic,
  PSTN,
  TollFreeNumbers,
  TwoWaySMS,
  APIavailable,
  EmergencyServices,
  MonitorDID,
  PortabilityAvailability,
  PSTNreplacement,
  TechSupport,
  RCS,
  Shield,
  Media,
  Delivery,
  Connect,
  Verify,
  Custom,
  Cards,
  Carousel,
  Action,
  Tick,
  Setup,
  Management,
  Analytics,
  Versatile,
  Channels,
  TwoWay,
  Domestic,
  International,
  Wifi,
  TollFree,
  Local,
  TollNumber

};
